<!-- Name -->
<script lang="ts">
export default {
  name: 'app-popup-dana'
};
</script>

<script setup lang="ts">

import { usePopupDanaStore } from '~/store/popup-dana';

const popupsStore = usePopupDanaStore();

const { show, idBanner } = storeToRefs(popupsStore);




</script>

<template>
  <div class="popup-home" v-if="show">
    <a class="click-outside" @click="popupsStore.closePopup"></a>
    <div class="content" v-if="idBanner == 1">
        <a class="btn-close rounded" @click="popupsStore.closePopup"> </a>
      <img class="d-none mx-auto d-lg-block img-fluid" src="/images/popup/popup-dana-modrive-d.png" />
      <img class="d-block mx-auto d-lg-none img-fluid" src="/images/popup/popup-dana-modrive-m.png" />
    </div>
      <div class="content" v-else>
        <a class="btn-close" @click="popupsStore.closePopup"> </a>
        <a href="https://ofertas.modrive.com/plan-ayuda-dana" target="_blank" class="w-100">
      <img class="d-none mx-auto d-lg-block img-fluid" src="/images/popup/popup-dana-modrive-d2.jpg" />
      <img class="d-block mx-auto d-lg-none img-fluid" src="/images/popup/popup-dana-modrive-m2.jpg" />
      </a>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.popup-home {
  position: fixed;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  a.click-outside{
      background: rgba(black, 0.8);
      width:100vw;
      height:100vh;
      left:0px;
      top:0px;
      z-index:1;
      position:fixed;
  }

  .content {
    z-index:2;
    max-width: 900px;
    overflow-y: auto;
    position: relative;

    a.btn-close {
      position: absolute;
      right:3%;
      top:5%;
      cursor: pointer;
      width:25px;
      height:25px;
      background-size:cover;
      padding:0px;

      &.rounded{
        right:5%;
        top:7%;

        @media screen and (max-width:991px){
          top:6%;
          right:10%;
          width:20px;
          height:20px;
        }
      }

      @media screen and (max-width:991px){
        right:6%;
        width:20px;
        height:20px;
      }

      img {
        width: 30px;
        height: auto;
      }
    }
  }

  
  

  
}
</style>
