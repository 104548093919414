import { ref } from 'vue';
import { defineStore } from 'pinia';

export const usePopupDanaStore = defineStore('popup-dana-store', () => {


  const route = useRoute();
  const marca = route.params?.marca;
  const allowedBrands = ['ford','kia','mini','bmw','nissan','hyundai','mg'];
  const showed = ref(false);


  const show = ref(marca == undefined || allowedBrands.includes(marca) ? true : false);
  const idBanner = ref(1);
  

  const closePopup = () => {
    show.value = false;
    if (idBanner.value == 1 && showed.value == false) {
      idBanner.value = 2;
        setTimeout(() => {
          show.value = true;
          showed.value = true;
        }, 30000);
    }
  };

  return {
    show,
    idBanner,
    closePopup
  };
});
